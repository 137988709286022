import {Experiments} from '../common/experiments/Experiments';
import {SPECS} from './constants';

export async function doTransaction(sdk: IEditorSdk, fn: Function, experiments: Experiments) {
  if (!experiments.enabled(SPECS.SupportConcurrentEditing)) {
    return fn();
  }

  try {
    return await sdk.document.transactions.runAndWaitForApproval('', fn);
  } catch (e) {
    const isTransactionError = await sdk.document.transactions.isConflictError('', e);
    if (isTransactionError) {
      return await sdk.document.transactions.runAndWaitForApproval('', fn);
    }
  }
}
