import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {ecomAppDefID} from '../constants';
import {Logger} from '@wix/bi-logger-ec-sf';

export async function adiMissingPagesMigration(sdk: IEditorSdk, appToken: string, storeId: string, biLogger: Logger) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId(appToken, ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll();
  const storesPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  const existsMandatoryPages = await Promise.all(
    storesPages.map((page) => {
      const pageRef = {id: page.id, type: page.type};
      return sdk.pages.data.get(appToken, {
        pageRef,
      });
    })
  ).then((pages: any) => {
    return pages.map((pageData) => pageData.tpaPageId);
  });

  let storesWasBroken = false;

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  biLogger.exposureEventForTests({
    appName: 'editor-script',
    testName: 'adi-fix-broken-stores-start',
    storeId,
  });

  const promises = [PageMap.CART, PageMap.PRODUCT, PageMap.THANKYOU].map((mandatoryPage) => {
    if (!existsMandatoryPages.includes(mandatoryPage)) {
      storesWasBroken = true;
      return sdk.tpa.add.component(appToken, {
        appDefinitionId: ecomAppDefID,
        componentType: 'PAGE',
        page: {
          pageId: mandatoryPage,
        },
      });
    } else {
      return Promise.resolve();
    }
  });

  await Promise.all(promises);

  if (storesWasBroken) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    biLogger.exposureEventForTests({
      appName: 'editor-script',
      testName: 'adi-fix-broken-stores-end',
      storeId,
    });
  }

  return Promise.resolve();
}
